<template>
  <div class="login-container">
    <div class="login-card">
      <h1>Login</h1>
      <div v-if="error" class="error">{{ error }}</div>
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="username">Username</label>
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="Enter your username"
            required
          >
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder="Enter your password"
            required
          >
        </div>
        <button type="submit" :disabled="loading" class="login-button">
          {{ loading ? 'Logging in...' : 'Login' }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {
      username: '',
      password: '',
      error: '',
      loading: false
    }
  },
  methods: {
    async handleLogin () {
      this.loading = true
      this.error = ''
      try {
        const response = await fetch('/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: this.username,
            password: this.password
          })
        })
        const data = await response.json()
        if (response.ok) {
          // Store auth token or user data if provided
          localStorage.setItem('isAuthenticated', 'true')
          // Redirect to home
          this.$router.push('/')
        } else {
          this.error = data.message || 'Login failed'
        }
      } catch (err) {
        this.error = 'An error occurred. Please try again.'
        console.error('Login error:', err)
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  padding: 20px;
}

.login-card {
  width: 100%;
  max-width: 400px;
  background-color: #1e1e1e;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  color: white;
}

h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.form-group {
  margin-bottom: 24px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #e0e0e0;
}

input {
  width: 100%;
  padding: 15px;
  background-color: #2d2d2d;
  border: 1px solid #3d3d3d;
  border-radius: 12px;
  font-size: 16px;
  color: white;
  transition: border-color 0.2s;
}

input::placeholder {
  color: #8d8d8d;
}

input:focus {
  outline: none;
  border-color: #b39ddb;
}

.login-button {
  width: 100%;
  padding: 16px;
  background: linear-gradient(135deg, #b39ddb 0%, #7e57c2 100%);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 15px;
  transition: opacity 0.2s;
}

.login-button:hover {
  opacity: 0.9;
}

.login-button:disabled {
  background: #4d4d4d;
  cursor: not-allowed;
}

.error {
  background-color: rgba(244, 67, 54, 0.1);
  color: #f44336;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  border-left: 4px solid #f44336;
}
</style>
